.header {
  text-align: center;
}

.toast {
  margin: 0 auto;
}

.custom-btn-toolbar {
  display: inline !important;
}

.custom-btn-toolbar > .btn {
  margin-right: 1rem;
}
