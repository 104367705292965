// change the theme
$theme-colors: (
  "primary": #1e7e34,
  "danger": #ff4136
);

$red: #c00;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

#approot {
  padding-top: 60px;
}

h1 {
  font-size: 2rem;
}

h1.title {
  margin-bottom: 2rem;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 1.2rem;
}

.error {
  color: $red;
}

#leftNav {
  .outer {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;

  }

  .inner {
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
  }

  .nav {
    margin-bottom: 1rem;
  }

  .nav-link {
    padding-left: 0;
  }

  h2 {
    font-size: 1.4rem;
  }
}

.allowance {
  font-weight: bold;
}

.negative {
  color: $red;
}

#periodList li {
  border-bottom: 1px solid #000;
  list-style: none;
  padding: 1rem 0;
}

#createAccountModal {
  input {
    margin-bottom: 1rem;
  }
}

#loginModal {
  input {
    margin-bottom: 1rem;
  }
}

#choresprint {
  font-size: 26px;

  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 32px;
  }
}

.choresForm {
  h3 {
    font-size: 1.1rem;
  }
}